<template>
    <v-container grid-list-xl fluid>
        <v-card>
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-title class="text-h5">
                        {{ board }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Gateway</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="details && details.version">
                <v-list-item-icon>
                    <v-icon>local_offer</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle><strong>Versão: </strong>{{ details.version }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="details && details.connection">
                <v-list-item-icon>
                    <v-icon>cable</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle><strong>Conexão: </strong>{{ details.connection }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="details">
                <v-list-item-icon>
                    <v-icon v-if="details.vpnIp">vpn_lock</v-icon>
                    <v-icon v-else color="warning">report_problem</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                    <v-btn small color="blue-grey" class="white--text" style="width: 100%" :href="vpnHref" target="_blank" v-if="details.vpnIp && isAdmin">
                        <v-icon left>vpn_lock</v-icon>
                        VPN: {{ details.vpnIp }}
                    </v-btn>
                    <strong v-else-if="details.vpnIp">VPN: {{ details.vpnIp }}</strong>
                    <strong v-else>Sem acesso VPN</strong>
                </v-list-item-subtitle>
            </v-list-item>
        </v-card>
        <v-divider class="mb-4" />

        <ExpandPanelSimple title="Configurações">
            <Form :itemToEdit="config" :lock="false" @save="init" controlAPI />
        </ExpandPanelSimple>
    </v-container>
</template>

<script>
import Form from "@/components/form/Board";
import ExpandPanelSimple from "@/components/core/ExpandPanelSimple.vue";

import { mapGetters } from "vuex";

export default {
    name: "Charger",

    components: { Form, ExpandPanelSimple },

    data() {
        return {
            isAdmin: false,
            details: null,
            board: null,
            config: null
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ROLE_ADMIN");
        if (this.$route.params.name) {
            this.board = this.$route.params.name;
        }
        this.init();
    },

    computed: {
        ...mapGetters(["verifyRole"]),

        vpnHref() {
            return this.details && this.details.vpnIp ? `http://${this.details.vpnIp}` : "";
        },
    },

    methods: {
        async init() {
            if (this.board) {
                await this.$http
                    .get(`api/v3/board/details/${this.board}`)
                    .then((result) => {
                        this.details = result;
                    })
                    .catch(() => {
                        this.$eventHub.$emit("msgError", "Erro ao buscar informações.");
                    });
                if (this.details && this.details.id) {
                    this.config = null;
                    await this.$http
                        .get(`api/v2/board/${this.details.id}`)
                        .then((result) => {
                            this.config = result;
                        })
                }
            }
        },
    },
};
</script>
